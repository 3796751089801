import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Authentication } from '../models/authentication';
import { User } from '../models/user';
import { Status } from '../models/status';
import { NotificationService } from './notification.service';
import { AccessStats } from '../models/access-stats';
import { CompactUser } from '../models/compact-user';
import { ChatUser } from '../models/chat-user';
import { AuthenticationSession } from '../models/authentication-session'
import { TwoFactorAuthenticator } from '../models/two-factor-authenticator';

@Injectable({ providedIn: 'root' })
export class UserService {

    private authenticationSubject: BehaviorSubject<Authentication>;
    public authentication: Observable<Authentication>;

    constructor(private router: Router, private http: HttpClient, private notificationService: NotificationService) {
        this.authenticationSubject = new BehaviorSubject<Authentication>(null);
        this.authentication = this.authenticationSubject.asObservable();
    }

    public get authenticationValue(): Authentication {
        return this.authenticationSubject.value;
    }

    login(body) {
        return this.http.post<AuthenticationSession>(`${environment.API}users/authenticate/session`, body).pipe();
    }

    validateSessionToken(body) {
        return this.http.post<any>(`${environment.API}users/session-token`, body)
            .pipe(map(authentication => {
                this.authenticationSubject.next(authentication);
                this.storeRefreshToken();
                this.startRefreshTokenTimer();
                return authentication;
            }));
    }

    logout() {
        this.http.post<any>(`${environment.API}users/revoke-token`, { token: this.getRefreshToken() }).subscribe();
        this.stopRefreshTokenTimer();
        this.authenticationSubject.next(null);
        this.router.navigate(['/login']);
    }

    kickout() {
        this.stopRefreshTokenTimer();
        this.authenticationSubject.next(null);
        this.router.navigate(['/login']);
        this.notificationService.info('Você foi desconectado por um administrador');
    }

    refreshToken() {
        return this.http.post<any>(`${environment.API}users/refresh-token`, { token: this.getRefreshToken() })
            .pipe(map((authentication) => {
                this.authenticationSubject.next(authentication);
                this.storeRefreshToken();
                this.startRefreshTokenTimer();
                return authentication;
            }));
    }

    getUsers(params?) {
        return this.http.get<User[]>(`${environment.API}users`, { params }).pipe();
    }

    getCompactUsers(params?) {
        return this.http.get<CompactUser[]>(`${environment.API}users/compact`, { params }).pipe();
    }

    getChatUser(params?) {
        return this.http.get<ChatUser[]>(`${environment.API}users/chat-user`, { params }).pipe();
    }

    getUserByID(params) {
        return this.http.get<User>(`${environment.API}users/id`, { params }).pipe();
    }

    updateUser(userID: number, body) {
        return this.http.put<any>(`${environment.API}users/${userID}`, body).pipe();
    }

    createUser(body) {
        return this.http.post<any>(`${environment.API}users`, body).pipe();
    }

    deleteUser(params) {
        return this.http.delete(`${environment.API}users`, { params }).pipe();
    }

    activateAssociate(body) {
        return this.http.post<any>(`${environment.API}users/activate-associate`, body).pipe();
    }

    deactivateAssociate(body) {
        return this.http.post<any>(`${environment.API}users/deactivate-associate`, body).pipe();
    }

    changePassword(userID: number, password: string, confirmPassword: string) {
        return this.http.post(`${environment.API}users/change-password`, { userID, password, confirmPassword });
    }

    sendCode(login: string, reCaptcha: string) {
        return this.http.post(`${environment.API}users/send-code`, { login, reCaptcha });
    }

    validateCode(code: string, verificationType: number) {
        return this.http.post(`${environment.API}users/validate-code`, { code, verificationType });
    }

    resetPassword(resetCode: string, password: string, confirmPassword: string) {
        return this.http.post(`${environment.API}users/reset-password`, { resetCode, password, confirmPassword });
    }

    uploadImage(image: any) {
        return this.http.post<any>(`${environment.API}users/upload-image`, image).pipe();
    }

    logoutFromAllSessions(userID: number) {
        return this.http.post<any>(`${environment.API}users/logout`, { userID: userID }).pipe(concatMap(auth => {

            if (userID == this.authenticationValue.userID) {
                this.stopRefreshTokenTimer();
                this.authenticationSubject.next(null);
                this.router.navigate(['/login']);
            }

            return auth;
        }))
    }

    getStatus() {
        return this.http.get<Status[]>(`${environment.API}users/status`).pipe();
    }

    updateUserStatus(body) {
        return this.http.put<any>(`${environment.API}users/status`, body).pipe();
    }
    
    enable2FA() {
        return this.http.post<TwoFactorAuthenticator>(`${environment.API}users/two-factor-authenticator/enable`, { }).pipe();
    }

    disable2FA() {
        return this.http.post<any>(`${environment.API}users/two-factor-authenticator/disable`, { }).pipe();
    }

    resetTwoFactorAuthenticator(login: string, reCaptcha: string) {
        return this.http.post(`${environment.API}users/two-factor-authenticator/recovery`, { login, reCaptcha });
    }

    getAccessStats(params) {
        return this.http.get<AccessStats>(`${environment.API}users/access-stats`, { params }).pipe();
    }

    // helper methods

    private refreshTokenTimeout: any;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.authenticationValue.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    private storeRefreshToken() {
        localStorage.setItem('rft-vt', this.authenticationValue.refreshToken);
    }

    private getRefreshToken() {
        return localStorage.getItem('rft-vt');
    }
}