import { Component, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { MessageService } from "src/app/services/message.service";

@Component({
    selector: 'app-snackbar-popup',
    templateUrl: './snackbar-popup.component.html',
    styleUrls: ['snackbar-popup.component.scss']
})

export class SnackbarComponent {
    title: string;
    message: string;
    params?: any;

    constructor(public snackBarRef: MatSnackBarRef<SnackbarComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: any,
        private router: Router, private messageService: MessageService) {
        this.title = data.title;
        this.message = data.message;
        this.params = data.params;
    }

    ngOnInit() {
        setTimeout(() => {
            this.snackBarRef.dismiss();
        }, 8000);
    }

    replyMessage() {
        this.snackBarRef.dismiss();
        this.router.navigate(['messaging'], { queryParams: { participantID: this.params?.participantID } });
    }

    readMessages() {
        this.snackBarRef.dismiss();
        this.messageService.readUnreadMessages({ senderID: this.params?.participantID }).subscribe();
    }

    dismiss() {
        this.snackBarRef.dismiss();
    }
}