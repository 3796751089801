import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Authentication } from '../models/authentication';
import { ConnectionSubscription } from '../view-models/connection-subscription';
import { MessageReceivedSubscription } from '../view-models/message-received-subscription';
import { MessageSubscription } from '../view-models/message-subscription';
import { StatusSubscription } from '../view-models/status-subscription';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public data: any[];
  private loggedUser: Authentication;
  private messageSubject: BehaviorSubject<MessageSubscription>;
  private messageReceivedSubject: BehaviorSubject<MessageReceivedSubscription>;
  private assignChatSubject: BehaviorSubject<MessageSubscription>;
  private readMessagesSubject: BehaviorSubject<MessageSubscription>;
  private typingMessageSubject: BehaviorSubject<MessageSubscription>;
  private recordingAudioSubject: BehaviorSubject<MessageSubscription>;
  private changeStatusSubject: BehaviorSubject<StatusSubscription>;
  private connectingSubject: BehaviorSubject<ConnectionSubscription>;
  private disconnectingSubject: BehaviorSubject<ConnectionSubscription>;
  public messageSubscription: Observable<MessageSubscription>;
  public messageReceivedSubscription: Observable<MessageReceivedSubscription>;
  public assignChatSubscription: Observable<MessageSubscription>;
  public readMessagesSubscription: Observable<MessageSubscription>;
  public typingMessageSubscription: Observable<MessageSubscription>;
  public recordingAudioSubscription: Observable<MessageSubscription>;
  public changeStatusSubscription: Observable<StatusSubscription>;
  public connectingSubscription: Observable<ConnectionSubscription>;
  public disconnectingSubscription: Observable<ConnectionSubscription>;

  constructor(private userService: UserService) {
    this.messageSubject = new BehaviorSubject<MessageSubscription>(null);
    this.messageReceivedSubject = new BehaviorSubject<MessageReceivedSubscription>(null);
    this.assignChatSubject = new BehaviorSubject<MessageSubscription>(null);
    this.readMessagesSubject = new BehaviorSubject<MessageSubscription>(null);
    this.typingMessageSubject = new BehaviorSubject<MessageSubscription>(null);
    this.recordingAudioSubject = new BehaviorSubject<MessageSubscription>(null);
    this.changeStatusSubject = new BehaviorSubject<StatusSubscription>(null);
    this.connectingSubject = new BehaviorSubject<ConnectionSubscription>(null);
    this.disconnectingSubject = new BehaviorSubject<ConnectionSubscription>(null);
    this.messageSubscription = this.messageSubject.asObservable();
    this.messageReceivedSubscription = this.messageReceivedSubject.asObservable();
    this.assignChatSubscription = this.assignChatSubject.asObservable();
    this.readMessagesSubscription = this.readMessagesSubject.asObservable();
    this.typingMessageSubscription = this.typingMessageSubject.asObservable();
    this.recordingAudioSubscription = this.recordingAudioSubject.asObservable();
    this.changeStatusSubscription = this.changeStatusSubject.asObservable();
    this.connectingSubscription = this.connectingSubject.asObservable();
    this.disconnectingSubscription = this.disconnectingSubject.asObservable();
  }

  private hubConnection: signalR.HubConnection
  public startConnection = () => {
    this.loggedUser = this.userService.authenticationValue;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.API}signal?userID=${this.loggedUser.userID}`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .withAutomaticReconnect([5, 10, 15, 30, 60, 120])
      .build();

    this.hubConnection.start();
  }

  public get getHubConnection() {
    return this.hubConnection;
  }

  public endConnection = () => {
    this.hubConnection.stop();
  }

  public addEventListener = () => {
    this.hubConnection.on('ReceiveMessage', (subscription: MessageSubscription) => {
      if (subscription) {
        this.messageSubject.next(subscription);
      }
    });

    this.hubConnection.on('MessageReceived', (subscription: MessageReceivedSubscription) => {
      if (subscription) {
        this.messageReceivedSubject.next(subscription);
      }
    });

    this.hubConnection.on('ChatAssigned', (subscription: MessageSubscription) => {
      if (subscription) {
        this.assignChatSubject.next(subscription);
      }
    });

    this.hubConnection.on('ReadMessages', (subscription: MessageSubscription) => {
      if (subscription) {
        this.readMessagesSubject.next(subscription);
      }
    });

    this.hubConnection.on('Typing', (subscription: any) => {
      if (subscription) {
        this.typingMessageSubject.next(subscription);
      }
    });

    this.hubConnection.on('RecordingAudio', (subscription: any) => {
      if (subscription) {
        this.recordingAudioSubject.next(subscription);
      }
    });

    this.hubConnection.on('ChangeStatus', (subscription: StatusSubscription) => {
      if (subscription) {
        this.changeStatusSubject.next(subscription);
      }
    });

    this.hubConnection.on('Connecting', (subscription: ConnectionSubscription) => {
      if (subscription) {
        this.connectingSubject.next(subscription);
      }
    });

    this.hubConnection.on('Disconnecting', (subscription: ConnectionSubscription) => {
      if (subscription) {
        this.disconnectingSubject.next(subscription);
      }
    });

    this.hubConnection.on('Kick out', (subscription: any) => {
      if (subscription && subscription.userID == this.loggedUser.userID) {
        this.userService.kickout();
      }
    });
  }
}