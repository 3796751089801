export class Role {
    id: number;
    description: string;

    static getAllowedRoles(permission: Permissions) {
        switch (permission) {
            case Permissions.ManageUsers:
                return [Roles.Admin];
            case Permissions.ManageRoles:
                return [Roles.Admin];
            case Permissions.ManageAssociates:
                return [Roles.Admin, Roles.AssociatesAdmin];
            case Permissions.ManageAssociateGroups:
                return [Roles.Admin, Roles.AssociatesAdmin];
            case Permissions.ManageJobs:
                return [Roles.Admin];
            case Permissions.ManageParameters:
                return [Roles.Admin];
            case Permissions.ManageAppVersions:
                return [Roles.Admin];
            case Permissions.ManagePosts:
                return [Roles.Admin, Roles.AssociatesAdmin, Roles.NewsEditor];
            case Permissions.ManageLegalPosts:
                return [Roles.Admin, Roles.AssociatesAdmin, Roles.ComplianceAdmin];
            case Permissions.ManageViticultureRegistrations:
                return [Roles.Admin, Roles.AssociatesAdmin];
            case Permissions.ManageItems:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ManagePriceLists:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ManageHarvests:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ViewAreas:
                return [Roles.Admin, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ManageAreas:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ViewProductions:
                return [Roles.Admin, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ViewMachinery:
                return [Roles.Admin, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ManageMachinery:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ViewChecklists:
                return [Roles.Admin, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ManageChecklists:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ViewPulverizerSetups:
                return [Roles.Admin, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ManagePulverizerSetups:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ViewOrders:
                return [Roles.Admin, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ManageOrders:
                return [Roles.Admin, Roles.AgroAdmin]
            case Permissions.ViewTechnicalVisits:
                return [Roles.Admin, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ManageTechnicalVisits:
                return [Roles.Admin, Roles.AgroAdmin];
            case Permissions.ViewInvoices:
                return [Roles.Admin, Roles.AssociatesAdmin];
            case Permissions.ViewHarvestReports:
                return [Roles.Admin, Roles.AssociatesAdmin];
            case Permissions.ViewFinance:
                return [Roles.Admin, Roles.AssociatesAdmin];
            case Permissions.ViewAcceptanceTerms:
                return [Roles.Admin, Roles.ComplianceAdmin, Roles.ComplianceViewer];
            case Permissions.ManageAcceptanceTerms:
                return [Roles.Admin, Roles.ComplianceAdmin];
            case Permissions.ViewTermsOfUse:
                return [Roles.Admin, Roles.ComplianceAdmin, Roles.ComplianceViewer];
            case Permissions.ManageTermsOfUse:
                return [Roles.Admin, Roles.ComplianceAdmin];
            case Permissions.ViewInspections:
                return [Roles.Admin, Roles.ComplianceAdmin, Roles.ComplianceViewer, Roles.AgroAdmin, Roles.AgroViewer];
            case Permissions.ManageInspections:
                return [Roles.Admin, Roles.ComplianceAdmin];
            case Permissions.ViewRiskRatings:
                return [Roles.Admin, Roles.ComplianceAdmin, Roles.ComplianceViewer];
            case Permissions.ManageRiskRatings:
                return [Roles.Admin, Roles.ComplianceAdmin];
            case Permissions.AccessMessages:
                return [Roles.Admin, Roles.AssociatesAdmin, Roles.AgroAdmin, Roles.ComplianceAdmin];
            case Permissions.ViewSidenavMenu:
                return [Roles.Admin, Roles.AssociatesAdmin, Roles.AgroAdmin, Roles.ComplianceAdmin, Roles.ComplianceViewer, Roles.AgroViewer];
            case Permissions.ViewAdminTabs:
                return [Roles.Admin, Roles.AssociatesAdmin, Roles.AgroAdmin, Roles.ComplianceAdmin, Roles.ComplianceViewer, Roles.AgroViewer];
            case Permissions.ViewDAPs:
                return [Roles.Admin, Roles.AssociatesAdmin];
        }
    }
}

export enum Roles {
    Admin = 'Admin',
    AssociatesAdmin = 'Associates Admin',
    AgroAdmin = 'Agro Admin',
    AgroViewer = 'Agro Viewer',
    ComplianceAdmin = 'Compliance Admin',
    ComplianceViewer = 'Compliance Viewer',
    NewsEditor = 'News Editor'
}

export enum Permissions {
    AccessMessages,
    ManageAppVersions,
    ManageAcceptanceTerms,
    ManageAreas,
    ManageAssociates,
    ManageAssociateGroups,
    ManageChecklists,
    ManageInspections,
    ManageItems,
    ManageHarvests,
    ManageJobs,
    ManageLegalPosts,
    ManageMachinery,
    ManageOrders,
    ManageParameters,
    ManagePosts,
    ManagePriceLists,
    ManagePulverizerSetups,
    ManageRiskRatings,
    ManageRoles,
    ManageTechnicalVisits,
    ManageTermsOfUse,
    ManageUsers,
    ManageViticultureRegistrations,
    ViewAcceptanceTerms,
    ViewAdminTabs,
    ViewAreas,
    ViewChecklists,
    ViewDAPs,
    ViewFinance,
    ViewHarvestReports,
    ViewInspections,
    ViewInvoices,
    ViewMachinery,
    ViewOrders,
    ViewProductions,
    ViewPulverizerSetups,
    ViewRiskRatings,
    ViewSidenavMenu,
    ViewTechnicalVisits,
    ViewTermsOfUse,
}