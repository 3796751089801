<title mat-dialog-title style="text-align: center;">Termos de Uso</title>
<mat-dialog-content class="dialog-content">
    <mat-stepper>
        <mat-step *ngFor="let item of termsOfUse" [label]="'Termo ' + (termsOfUse.indexOf(item) + 1)">
            <quill-view-html class="full-container" [content]="item.description"></quill-view-html>
            <br>
            <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-bottom: -24px">
                <p>
                    <mat-checkbox [(ngModel)]="item.isAccepted" (click)="$event.stopPropagation()">Li e aceito os
                        termos</mat-checkbox>
                </p>
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
                    <button mat-button matStepperPrevious *ngIf="termsOfUse.indexOf(item) > 0">Voltar</button>
                    <button mat-raised-button color="primary" matStepperNext
                        *ngIf="termsOfUse.indexOf(item) < (termsOfUse.length - 1)">Avançar</button>
                    <button mat-raised-button color="primary"
                        *ngIf="termsOfUse.indexOf(item) == (termsOfUse.length - 1)" (click)="save()">Finalizar</button>
                </div>
            </div>
        </mat-step>
    </mat-stepper>
</mat-dialog-content>