<mat-dialog-content>
    <h1 mat-dialog-title>Selecione um termo</h1>
    <mat-form-field class="full-container">
        <mat-label>Termo <mat-icon *ngIf="selectedAcceptanceTerm">push_pin</mat-icon>
        </mat-label>
        <input #acceptanceTermInput matInput [matAutocomplete]="autoAcceptanceTerm" class="search-input"
            (input)="filterBySearch(acceptanceTermInput.value)" [readonly]="selectedAcceptanceTerm" placeholder="PESQUISAR...">
        <button mat-icon-button matSuffix (click)="applySearchSelection(null);" *ngIf="acceptanceTermInput.value.length > 0"
            class="btn-remove" matTooltip="Remover">
            <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #autoAcceptanceTerm="matAutocomplete" autoActiveFirstOption>
            <mat-option *ngFor="let acceptanceTerm of filteredAcceptanceTerms" (onSelectionChange)="applySearchSelection(acceptanceTerm)"
                [value]="acceptanceTerm.title">
                <small>{{ acceptanceTerm.title.toLocaleUpperCase() }}</small>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>
