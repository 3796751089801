import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
    selector: 'app-crop-image',
    templateUrl: './crop-image.component.html',
    styleUrls: ['./crop-image.component.scss']
})

export class CropImageComponent implements OnInit {
    image: any;
    croppedImage: any;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CropImageComponent>) {
        this.image = this.data.image;
    }

    ngOnInit() {

    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    cancel() {
        this.dialogRef.close();
    }

    apply() {
        let byteString = atob(this.croppedImage.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
        let arrayBuffer = new ArrayBuffer(byteString.length);
        let int8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }

        let file = new Blob([int8Array], { type: this.image.type });
        let formData = new FormData();
        formData.append('image', file);

        this.dialogRef.close(formData);
    }
}