<div class="container-content">
  <div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 class="mat-headline">Configurações pessoais</h1>
  </div>
  <mat-divider></mat-divider>
  <h4 class="mat-small" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
    <mat-icon>privacy_tip</mat-icon>
    <label>Defina suas preferência pessoais de uso.</label>
  </h4>
  <div>
    <mat-card class="cover-image"></mat-card>
    <mat-card class="profile-card mat-elevation-z8">
      <div fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" class="profile-info">
          <a (click)="dialog.click()" matTooltip="Clique para alterar a foto" DragAndDropDirective
            (fileDropped)="changeProfilePicture($event)" class="drop-area">
            <img [class]="user?.image != null ? 'profile-picture border' : 'profile-picture'"
              [src]="user?.image ?? (this.loggedUser.isAssociate ? 'assets/images/default-associate.png' : 'assets/images/default-admin.png')">
          </a>
          <div fxLayout="column" fxLayoutGap="0px" class="profile-info-text">
            <label class="mat-title profile-info-text">{{ user?.name }}</label>
            <small class="profile-info-text">{{ user?.email }}</small>
          </div>
        </div>
        <mat-stepper orientation="vertical" #stepper>
          <mat-step>
            <ng-template matStepLabel>Informações Pessoais</ng-template>
            <div *ngIf="associate" fxLayout="column" [formGroup]="form">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field class="full-container search-input readonly">
                  <mat-label>Endereço</mat-label>
                  <input matInput formControlName="address" readonly>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field class="full-container search-input readonly">
                  <mat-label>CPF</mat-label>
                  <input matInput [value]="associate?.cpf" readonly>
                </mat-form-field>
                <mat-form-field class="full-container search-input">
                  <mat-label>Estado Civíl</mat-label>
                  <mat-select formControlName="maritalStatus" (selectionChange)="checkMaritalRegime()">
                    <mat-option *ngFor="let status of maritalStatus" [value]="status.key">
                      {{ status.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field class="full-container search-input readonly">
                  <mat-label>RG</mat-label>
                  <input matInput [value]="associate?.rg" readonly>
                </mat-form-field>
                <mat-form-field class="full-container search-input">
                  <mat-label>Regime de Comunhão</mat-label>
                  <mat-select formControlName="maritalRegime">
                    <mat-option *ngFor="let regime of maritalRegimes" [value]="regime.key">
                      {{ regime.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                <mat-form-field class="full-container search-input readonly">
                  <mat-label>Telefone</mat-label>
                  <input matInput formControlName="phoneNumber" readonly>
                </mat-form-field>
                <mat-form-field class="full-container search-input">
                  <mat-label>Escolaridade</mat-label>
                  <mat-select formControlName="educationLevel">
                    <mat-option *ngFor="let level of educationLevels" [value]="level.key">
                      {{ level.description }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <button mat-raised-button color="primary" [disabled]="noChanges" (click)="save()">Salvar</button>
              </div>
            </div>
            <div *ngIf="!associate">
              <small>Nada para exibir</small>
            </div>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Configurações da Conta</ng-template>

            <p fxLayout="column" fxLayoutGap="15px">
              <button mat-stroked-button color="primary" (click)="showChangePasswordDialog()" class="action-button">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                  <mat-icon>vpn_key</mat-icon>
                  <label>Alterar minha senha</label>
                </div>
              </button>
              <button mat-stroked-button color="primary" (click)="dialog.click()" class="action-button">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <mat-icon>image</mat-icon>
                  <label>Alterar foto de perfil</label>
                </div>
              </button>
              <small>* A imagem deve estar no formato .png ou .jpeg (400KB)</small>
              <input hidden type="file" #dialog (change)="changeProfilePicture($event)" accept=".png, .jpeg, .jpg">
              <br>
              <mat-divider></mat-divider>
              <br>
              <small>Utilizar autenticação de dois fatores nesta conta?</small>
              <mat-slide-toggle style="padding: 0px 0px 25px 10px;" [checked]="user?.is2FAEnabled"
                (change)="toggle2FA()" color="primary">{{ user?.is2FAEnabled ? 'Habilitado' : 'Desabilitado' }}</mat-slide-toggle>
            </p>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>Minhas Sessões</ng-template>
            <p fxLayout="column" fxLayoutGap="15px">
              <button mat-stroked-button color="primary" (click)="logoutFromAllSessions()" class="action-button">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                  <mat-icon>logout</mat-icon>
                  <label>Sair de todas sessões</label>
                </div>
              </button>
            </p>
          </mat-step>
        </mat-stepper>
      </div>
    </mat-card>
  </div>
</div>