import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AppVersion } from 'src/app/models/app-version';
import { AppVersionsService } from 'src/app/services/app-versions.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-mobile-warning',
    templateUrl: './mobile-warning.component.html',
    styleUrls: ['./mobile-warning.component.scss']
})

export class MobileWarningComponent implements OnInit {
    appVersion?: AppVersion;

    constructor(private appVersionsService: AppVersionsService, private notificationService: NotificationService) { }

    ngOnInit() {
        this.appVersionsService.getLatestAppVersion().subscribe({
            next: appVersion => {
                this.appVersion = appVersion;
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    downloadApp() {
        this.appVersionsService.downloadAppVersion({ code: this.appVersion?.code }).subscribe(event => {
            if (event.type === HttpEventType.Response) {
                this.notificationService.sucess('Versão baixada.');

                const blob = new Blob([event.body], { type: 'application/vnd.android.package-archive' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');

                link.href = url;
                link.download = `v-${this.appVersion?.code}.apk`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        });
    }
}