import { Component, OnInit } from '@angular/core';
import { concatMap } from 'rxjs';
import { PushNotification } from 'src/app/models/push-notification';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit {
    notifications: PushNotification[];
    unreadOnly: boolean = false;

    constructor(private notificationService: NotificationService) { }

    ngOnInit() {
        this.getNotifications();
    }

    getNotifications() {
        this.notificationService.getNotifications({ unreadOnly: this.unreadOnly }).subscribe({
            next: notifications => {
                this.notifications = notifications;
                this.notificationService.notificationCountSubject.next(notifications.filter(n => n.unread).length);
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    readNotifications() {
        this.notificationService.readNotifications().pipe(concatMap(() => {
            
            return this.notificationService.getNotifications({ unreadOnly: this.unreadOnly });
        })).subscribe({
            next: notifications => {
                this.notifications = notifications;
                this.notificationService.notificationCountSubject.next(notifications.filter(n => n.unread).length);
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    displayInfo() {
        this.notificationService.info('Para fins de uma melhor experiência, nós só trazemos suas notificações dos últimos 30 dias ou notificações que ainda não foram lidas.')
    }
}
