import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../helpers/confirm-dialog/confirm-dialog.component';
import { Extensions } from '../helpers/extensions';
import { Authentication } from '../models/authentication';
import { Post } from '../models/post';
import { MessageService } from '../services/message.service';
import { NotificationService } from '../services/notification.service';
import { SignalrService } from '../services/signalr.service';
import { UserService } from '../services/user.service';
import { AddPostComponent } from './add-post/add-post.component';
import { PostService } from '../services/post.service';
import { Permissions, Role, Roles } from '../models/role';
import { AddPollComponent } from './add-poll/add-poll.component';
import { Poll } from '../models/poll';
import { PollOption } from '../models/poll-option';
import { ViewPollVotesComponent } from './view-poll-votes/view-poll-votes.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  posts = new MatTableDataSource<Post | Poll>();
  loggedUser: Authentication;
  unreadChats: number;
  messageSubscription: Subscription;
  allowPostManagement: boolean;
  allowEditingAnyPost: boolean;
  allowMessagesAccess: boolean;
  chips = [
    { label: 'Todos', value: PostFilters.All, target: PostFilterTargets.All, selected: true },
    { label: 'Não vistas', value: PostFilters.NotSeen, target: PostFilterTargets.All, selected: false },
    { label: 'Somente para mim', value: PostFilters.HasTargetUser, target: PostFilterTargets.Associates, selected: false },
    { label: 'Destinatário definido', value: PostFilters.HasTargetUser, target: PostFilterTargets.Admins, selected: false },
    { label: 'Curtidas', value: PostFilters.Liked, target: PostFilterTargets.All, selected: false },
    { label: 'Não curtidas', value: PostFilters.NotLiked, target: PostFilterTargets.All, selected: false },
    { label: 'Imagens', value: PostFilters.Images, target: PostFilterTargets.All, selected: false },
    { label: 'Vídeos', value: PostFilters.Videos, target: PostFilterTargets.All, selected: false },
    { label: 'Enquetes', value: PostFilters.Polls, target: PostFilterTargets.All, selected: false },
  ];

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  constructor(private postService: PostService, private notificationService: NotificationService, private userService: UserService,
    private dialog: MatDialog, private messageService: MessageService, private router: Router, public signalRService: SignalrService) { }

  ngOnInit() {
    this.loggedUser = this.userService.authenticationValue;
    this.allowPostManagement = Extensions.isUserInAnyRole(this.loggedUser, Role.getAllowedRoles(Permissions.ManagePosts));
    this.allowMessagesAccess = this.loggedUser.isAssociate || Extensions.isUserInAnyRole(this.loggedUser, Role.getAllowedRoles(Permissions.AccessMessages));
    this.allowEditingAnyPost = Extensions.isUserInRole(this.loggedUser, Roles.Admin);

    this.chips = this.chips.filter(c => {
      if (c.target == PostFilterTargets.All) {
        return c;
      }
      else if (c.target == PostFilterTargets.Associates && this.loggedUser?.isAssociate) {
        return c;
      }
      else if (c.target == PostFilterTargets.Admins && !this.loggedUser?.isAssociate) {
        return c;
      }
    });

    this.messageSubscription = this.signalRService.messageSubscription.subscribe({
      next: subscription => {
        if (subscription) {
          this.messageService.getChats().subscribe({
            next: chats => {
              this.unreadChats = chats.filter(c => c.unread).length;
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      }
    });

    this.getPosts();
    this.messageService.getChats().subscribe({
      next: chats => {
        this.unreadChats = chats.filter(c => c.unread).length;
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }

  getPosts() {
    forkJoin([this.postService.getPosts(), this.postService.getPolls()]).subscribe({
      next: data => {
        this.posts.data = [...data[0].map(x => new Post(x)), ...data[1].map(x => new Poll(x))];
        this.posts.data.sort((a, b) => new Date(a.postDate) >= new Date(b.postDate) ? -1 : 1);
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  updatePostVisibility(post: Post, isVisible: boolean) {
    if (isVisible && post.unread) {
      this.readPost(post);
    }
  }

  applyFilter = (value: string) => {
    let selectedChip = this.chips.find(c => c.selected);

    if (selectedChip != null) {
      this.selectFilter(selectedChip)
    }
  }

  selectFilter(chip: any) {
    this.chips.forEach(c => {
      c.selected = c == chip;
    });

    var posts = this.posts;
    posts.filter = this.searchInput.nativeElement?.value?.trim().toLocaleLowerCase();

    switch (chip.value) {
      case PostFilters.All:
        this.posts.filteredData = posts.filteredData;
        break;
      case PostFilters.NotSeen:
        this.posts.filteredData = posts.filteredData.filter(p => (p instanceof Post) && p.unread);
        break;
      case PostFilters.HasTargetUser:
        this.posts.filteredData = posts.filteredData.filter(p => (p instanceof Post) && p.hasTargetUser);
        break;
      case PostFilters.Liked:
        this.posts.filteredData = posts.filteredData.filter(p => (p instanceof Post) && p.isLiked);
        break;
      case PostFilters.NotLiked:
        this.posts.filteredData = posts.filteredData.filter(p => (p instanceof Post) && !p.isLiked);
        break;
      case PostFilters.Images:
        this.posts.filteredData = posts.filteredData.filter(p => (p instanceof Post) && p.attachments.filter(a => a.fileType.startsWith('image')).length > 0);
        break;
      case PostFilters.Videos:
        this.posts.filteredData = posts.filteredData.filter(p => (p instanceof Post) && p.attachments.filter(a => a.fileType.startsWith('video')).length > 0);
        break;
      case PostFilters.Polls:
        this.posts.filteredData = posts.filteredData.filter(p => (p instanceof Poll));
        break;
    }
  }

  ratePost(post: Post) {
    this.postService.ratePost(post.id, { postID: post.id, isLiked: !post.isLiked }).subscribe({
      next: () => {
        post.isLiked = !post.isLiked;
        post.likedByCount += post.isLiked ? 1 : -1;
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  addPost() {
    const dialogRef = this.dialog.open(AddPostComponent, { panelClass: "dialog-extra-large", data: { isStatic: false } });

    dialogRef.afterClosed().subscribe({
      next: post => {
        if (post) {
          this.postService.createPost(post).subscribe({
            next: () => {
              this.notificationService.sucess('Publicação adicionada.');
              this.getPosts();
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      }
    });
  }

  editPost(post: Post) {
    const dialogRef = this.dialog.open(AddPostComponent, {
      panelClass: "dialog-large", data: { post: post, isStatic: false }
    });

    dialogRef.afterClosed().subscribe({
      next: editedPost => {
        if (editedPost) {
          editedPost.ID = post.id;
          this.postService.updatePost(post.id, editedPost).subscribe({
            next: () => {
              this.notificationService.sucess('Publicação atualizada.');
              this.getPosts();
            }
          });
        }
      }
    });
  }

  deletePost(post: Post) {
    const message = `Deseja realmente excluir a publicação <strong>${post.title}</strong>?`;
    const dialogData = new ConfirmDialogModel("Exclusão", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.postService.deletePost(post.id).subscribe({
            next: () => {
              this.notificationService.sucess("Publicação excluída.");
              this.getPosts();
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      }
    );
  }

  readPost(post: Post) {
    this.postService.readPost(post.id, { postID: post.id }).subscribe({
      next: () => {
        post.unread = false;
        post.readByCount++;
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  addPoll() {
    const dialogRef = this.dialog.open(AddPollComponent, { panelClass: "dialog-large" });

    dialogRef.afterClosed().subscribe({
      next: poll => {
        if (poll) {
          this.postService.createPoll(poll).subscribe({
            next: () => {
              this.notificationService.sucess('Enquete adicionada.');
              this.getPosts();
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      }
    });
  }

  deletePoll(poll: Poll) {
    const message = `Deseja realmente excluir a enquete <strong>${poll.title}</strong>?`;
    const dialogData = new ConfirmDialogModel("Exclusão", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.postService.deletePoll(poll.id).subscribe({
            next: () => {
              this.notificationService.sucess("Enquete excluída.");
              this.getPosts();
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      }
    );
  }

  voteInPoll(poll: Poll, option: PollOption) {
    this.postService.voteInPoll({ pollID: poll.id, optionID: option.id }).subscribe({
      next: () => {
        this.notificationService.sucess("Voto confirmado.");
        this.getPosts();
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  viewPollVotes(poll: Poll) {
    this.dialog.open(ViewPollVotesComponent, { panelClass: "dialog-extra-large", data: { poll: poll } });
  }

  goToMessages() {
    this.router.navigate(['/messaging']);
  }

  getPostDescription(post: Post) {
    return post.description?.replace(/\n/g, '<br>');
  }

  isFileImage(fileType: string) {
    switch (fileType) {
      case 'image/png':
        return true;
      case 'image/jpeg':
        return true;
      case 'image/jpg':
        return true;
      default:
        return false;
    }
  }

  isPost(post: Post | Poll): boolean {
    return post instanceof Post;
  }

  isPoll(post: Post | Poll): boolean {
    return post instanceof Poll;
  }
}

enum PostFilters {
  All,
  NotSeen,
  HasTargetUser,
  Liked,
  NotLiked,
  Images,
  Videos,
  Polls,
}

enum PostFilterTargets {
  All,
  Admins,
  Associates
}
