<h1 mat-dialog-title>
    {{title}}
  </h1>
  
  <div mat-dialog-content>
    <p [innerHTML]="message"></p>
  </div>
  
  <div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Não</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Sim</button>
  </div>
