import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { RecaptchaDialogComponent } from '../recaptcha-dialog/recaptcha-dialog.component';

@Component({
    selector: 'app-two-factor-authentication',
    templateUrl: './two-factor-authentication.component.html',
    styleUrls: ['./two-factor-authentication.component.scss']
})

export class TwoFactorAuthenticationComponent implements OnInit {
    sessionToken: string;
    login: string;
    loading: boolean = false;
    form: UntypedFormGroup;

    constructor(private userService: UserService, private notificationService: NotificationService, private router: Router,
        private route: ActivatedRoute, private dialog: MatDialog) { }

    ngOnInit() {
        this.sessionToken = this.route.snapshot.queryParams['sessionToken'];
        this.login = this.route.snapshot.queryParams['login'];
        this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

        if (this.sessionToken == null || this.login == null) {
            this.router.navigate(['/login']);
            return;
        }

        this.form = new UntypedFormGroup({
            twoFactorAuthenticatorPIN: new UntypedFormControl(null, [Validators.required])
        });
    }

    onSubmit() {
        if (this.form.invalid) {
            return;
        }

        this.loading = true;

        this.userService.validateSessionToken({ sessionToken: this.sessionToken, twoFactorAuthenticatorPIN: this.form.value.twoFactorAuthenticatorPIN }).subscribe({
            next: () => {
                const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
                this.router.navigateByUrl(returnUrl);
            },
            error: error => {
                this.notificationService.error(error);
                this.loading = false;
            }
        });
    }

    lostAuthenticator() {
        const dialogRef = this.dialog.open(RecaptchaDialogComponent);

        dialogRef.afterClosed().subscribe({
            next: reCaptcha => {
                if (reCaptcha) {
                    this.loading = true;
                    this.userService.resetTwoFactorAuthenticator(this.login, reCaptcha).subscribe({
                        next: () => {
                            this.notificationService.info("Instruções foram enviadas para o seu e-mail.");
                            this.loading = false;
                        },
                        error: error => {
                            this.notificationService.error(error);
                            this.loading = false;
                        }
                    });
                }
            }
        });
    }
}
