<div>
    <div class="pop">
        <div fxLayout="column" fxLayoutAlign="start center">
            <h6 class="mat-headline">Olá, {{ user?.name.split(' ')[0] }}!</h6>
            <label class="mat-body">Gostaríamos de sugerir a você a ativação da autenticação de dois fatores (2FA). Este
                recurso ajuda a adicionar uma camada extra de segurança à sua conta.</label>
            <img class="profile-setup" src="assets/images/authenticator.png">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
                <button mat-button (click)="close()" color="primary" tabindex="-1">Agora não</button>
                <button mat-raised-button color="primary" (click)="activate()" tabindex="-1">Quero habilitar</button>
            </div>
        </div>
    </div>
</div>