import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { User } from "src/app/models/user";
import { NotificationService } from "src/app/services/notification.service";
import { UserService } from "src/app/services/user.service";

@Component({
    selector: 'app-update-profile',
    templateUrl: './update-profile.component.html',
    styleUrls: ['./update-profile.component.scss']
})

export class UpdateProfileComponent implements OnInit {
    user: User;
    department: any;
    departments: any[];

    constructor(private dialogRef: MatDialogRef<UpdateProfileComponent>, private notificationService: NotificationService, private userService: UserService) { }

    ngOnInit() {
        this.userService.getUserByID({ ID: this.userService.authenticationValue?.userID }).subscribe({
            next: user => {
                this.user = user;
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }
    
    activate() {
        this.dialogRef.close(true);
    }

    close() {
        this.dialogRef.close(false);
    }
}