export class Notification {
    id: string;
    type: NotificationType;
    title?: string;
    message: string;
    action: string;
    autoClose: boolean;
    params?: any;

    constructor(init?: Partial<Notification>) {
        Object.assign(this, init);
    }
}

export enum NotificationType {
    Success,
    Error,
    Info,
    Warning,
    Forbid,
    MessagePopUp
}