import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { VerificationType } from '../public/verify-code/verify-code.component';
import { NotificationService } from '../services/notification.service';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private userService: UserService, private notificationService: NotificationService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const loggedUser = this.userService.authenticationValue;
        if (loggedUser) {
            
            if (!loggedUser.isAccountActivated) {
                this.router.navigate(['/verify-code'], { queryParams: { type: VerificationType.ActivateAccount } });
                return;
            }

            // check if route is restricted by role
            if (route.data.roles && !route.data.roles.some(r => loggedUser.roles.includes(r))) {
                if (route.data.ignoreRolesWhenAssociate && loggedUser.isAssociate) {
                    return true;
                }

                // role not authorized so redirect to messages
                this.notificationService.forbid();
                return false;
            }

            // authorized so return true
            return true;
        }

        // not logged in so redirect to login page with the return url 
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}