import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import { Chat } from '../models/chat';
import { Encryptions } from './encryptions';

@Pipe({
    name: 'decrypt_chat'
})
export class ChatDecryptionPipe implements PipeTransform {

    constructor(private userService: UserService) { }

    transform(chat: Chat) {
        if (chat.isLastMessageDeleted) {
            return 'Esta mensagem foi excluída';
        }

        if (chat.lastMessage == '# AUDIO #') {
            chat.isLastMessageAudio = true;
            return 'Mensagem de áudio';
        }

        if (chat.isLastSender) {
            var decryption = Encryptions.decryptAES(chat.lastMessage, this.userService.authenticationValue.userID);
            chat.isLastMessageCorrupted = decryption.isCorrupt;

            return !chat.isLastMessageCorrupted ? `Você: ${decryption.content}` : decryption.content;
        }
        else {
            var decryption = Encryptions.decryptAES(chat.lastMessage, chat.participantID);
            chat.isLastMessageCorrupted = decryption.isCorrupt;

            return decryption.content;
        }
    }
}