import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '../models/message';
import { UserService } from '../services/user.service';
import { Encryptions } from './encryptions';

@Pipe({
    name: 'decrypt_message'
})
export class MessageDecryptionPipe implements PipeTransform {

    constructor(private userService: UserService) { }

    transform(message: Message, participantID?: number) {
        if (message.isDeleted) {
            return 'Esta mensagem foi excluída';
        }

        if (message.audio != null) {
            return 'Mensagem de áudio';
        }

        if (message.isSender) {
            var decryption = Encryptions.decryptAES(message.content, this.userService.authenticationValue.userID);
            message.isCorrupted = decryption.isCorrupt;

            return decryption.content;
        }
        else {
            var decryption = Encryptions.decryptAES(message.content, participantID);
            message.isCorrupted = decryption.isCorrupt;

            return decryption.content;
        }
    }
}