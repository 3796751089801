import { Component, EventEmitter, Input, Output } from '@angular/core';
import { speedDialFabAnimations } from './speed-dial.animations';

@Component({
    selector: 'app-speed-dial',
    templateUrl: './speed-dial.component.html',
    styleUrls: ['./speed-dial.component.scss'],
    animations: speedDialFabAnimations
})

export class SpeedDialComponent {
    buttons = [];
    fabTogglerState = 'inactive';

    constructor() { }

    showItems() {
        this.fabTogglerState = 'active';
        this.buttons = this._buttonList;
    }

    hideItems() {
        this.fabTogglerState = 'inactive';
        this.buttons = [];
    }

    onToggleFab() {
        this.buttons.length ? this.hideItems() : this.showItems();
    }

    @Input()
    get position(): string { return this._position; }
    set position(position: string) {
        this._position = (position && position.trim()) || 'bottom-right';
    }
    private _position = 'bottom-right';

    @Input()
    get buttonList(): any[] { return this._buttonList; }
    set buttonList(buttonList: any[]) {
        this._buttonList = buttonList;
    }
    private _buttonList = [];

    @Output() onButtonClick: EventEmitter<void> = new EventEmitter<void>();

    buttonClick(action: void) {
        this.hideItems();
        this.onButtonClick.emit(action);
    }
}
