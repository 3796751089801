import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Chat } from '../models/chat';
import { Message } from '../models/message';

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  constructor(private http: HttpClient) { }

  getChats() {
    return this.http.get<Chat[]>(`${environment.API}messages/chats`).pipe();
  }

  getMessages(params) {
    return this.http.get<Message[]>(`${environment.API}messages`, { params }).pipe();
  }

  sendMessage(body) {
    return this.http.post<any>(`${environment.API}messages`, body).pipe();
  }

  replyToMessage(body) {
    return this.http.post<any>(`${environment.API}messages/reply`, body).pipe();
  }

  deleteMessage(params) {
    return this.http.delete<any>(`${environment.API}messages`, { params }).pipe();
  }
  
  takeOverChat(body) {
    return this.http.put<any>(`${environment.API}messages`, body).pipe();
  }

  readUnreadMessages(body) {
    return this.http.put<any>(`${environment.API}messages/read-messages`, body).pipe();
  }

  getAudioFile(url) {
    return this.http.get<ArrayBuffer>(url, { responseType: 'arraybuffer' as 'json' }).pipe();
  }
}
