import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from './services/notification.service';
import { Subscription } from 'rxjs';
import { MessageDialogComponent } from './helpers/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Notification, NotificationType } from './view-models/notification';
import { LoadingService } from './services/loading.service';
import { delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SnackbarComponent } from './helpers/snackbar-popup/snackbar-popup.component';
import { RequestProgress } from './view-models/request-progress';
import { Extensions } from './helpers/extensions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [],
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'minha-coop';
  loading: boolean = false;
  requestProgress: RequestProgress;
  notificationSubscription: Subscription;
  targetURL = '';
  allowPortrait = ['/', '/settings'];

  constructor(private notificationService: NotificationService, private loadingService: LoadingService, private snackBar: MatSnackBar,
    private dialog: MatDialog, private router: Router, private deviceService: DeviceDetectorService) {

    this.notificationSubscription = this.notificationService.onNotify('default-alert')
      .subscribe(notification => {
        if (notification) {
          if (notification.autoClose) {
            this.snackBar.open(notification.message, notification.action, { duration: 3000 });
          }
          else {
            if (notification.type == NotificationType.Success) {
              this.snackBar.open(notification.message, notification.action);
            }
            else if (notification.type == NotificationType.MessagePopUp) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                panelClass: 'popup', data: { title: notification.title, message: notification.message, params: notification.params }
              });
            }
            else {
              this.showMessageDialog(notification);
            }
          }
        }
      });
  }

  showMessageDialog(notification: Notification) {
    this.dialog.open(MessageDialogComponent, { data: notification });
  }

  ngOnInit() {
    this.listenToLoading();

    if (!this.deviceService.isDesktop()) {
      this.router.navigate(['/mobile'])
    }
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
  }

  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0))
      .subscribe((loading) => {
        this.loading = loading;
      });

    this.loadingService.progressSub
      .pipe(delay(0))
      .subscribe((requestProgress) => {
        this.requestProgress = requestProgress;
      });

  }

  formatStorage(bytes: number) {
    return Extensions.formatStorage(bytes);
  }
}