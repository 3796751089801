import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { DragAndDropDirective } from './profile/drag-and-drop.directive';
import { MaterialModule } from '../view-models/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HelpersModule } from '../helpers/helpers.module';
import { ConfigureTwoFactorAuthenticatorComponent } from './configure-two-factor-authenticator/configure-two-factor-authenticator.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';

@NgModule({
  declarations: [
    ProfileComponent,
    NotificationsComponent,
    DragAndDropDirective,
    ChangePasswordComponent,
    ConfigureTwoFactorAuthenticatorComponent,
    UpdateProfileComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HelpersModule
  ],
  exports: [
    DragAndDropDirective
  ]
})

export class SettingsModule { }
