export interface ComboBox {
    key: string;
    description: string;
}

export class MaritalStatus {

    static List = [
        { key: 'S', description: 'Solteiro' },
        { key: 'C', description: 'Casado' },
        { key: 'D', description: 'Divorciado' },
        { key: 'V', description: 'Viúvo' },
        { key: 'P', description: 'Separado' },
        { key: 'U', description: 'União Estável' },
    ] as ComboBox[];
}

export class MaritalRegime {

    static List = [
        { key: 'U', description: 'Comunhão Universal de Bens' },
        { key: 'P', description: 'Comunhão Parcial de Bens' },
        { key: 'S', description: 'Separação de Bens' },
        { key: 'F', description: 'Participação Final dos Aquestos' },
    ] as ComboBox[];
}

export class EducationLevels {

    static List = [
        { key: 'PGI', description: 'Primeiro Grau Incompleto' },
        { key: 'PGC', description: 'Primeiro Grau Completo' },
        { key: 'SGI', description: 'Segundo Grau Incompleto' },
        { key: 'SGC', description: 'Segundo Grau Completo' },
        { key: 'SI', description: 'Superior Incompleto' },
        { key: 'SC', description: 'Superior Completo' },
    ] as ComboBox[];
}