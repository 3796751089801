<div class="container-content">
    <div class="unread-button" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-slide-toggle [(ngModel)]="unreadOnly" (change)="getNotifications()">Somente Não Lidas</mat-slide-toggle>
        <button mat-raised-button color="primary" (click)="readNotifications()">Marcar todas como lidas</button>
    </div>
    <br>
    <mat-card class="notification-card" [ngClass]="{ 'notification-unread': notification.unread }"
        *ngFor="let notification of notifications">
        <p fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon>{{ notification.unread ? 'notifications_active' : 'notifications' }}</mat-icon>
            <small>{{ notification.sent | date:"dd/MM/yyyy HH:mm" }}</small>
        </p>
        <mat-card-subtitle>{{ notification.content }}</mat-card-subtitle>
        <mat-card-actions *ngIf="notification.unread">
            <button mat-button color="primary">Notificação não lida</button>
        </mat-card-actions>
    </mat-card>
    <app-empty-results *ngIf="notifications?.length <= 0"></app-empty-results>
    <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-button color="primary" *ngIf="notifications?.length > 0" (click)="displayInfo()">
            <mat-icon class="material-icons-outlined">info</mat-icon>
            <label> Por que minhas notificações estão
                desaparecendo?</label>
        </button>
    </div>
</div>