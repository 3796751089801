import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'link'
})
export class LinkPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(content: string) {
        content = this.preventHtml(content);
        var words = content.split(' ');

        for (var i = 0; i < words.length; i++) {
            try {
                let url = new URL(words[i]);

                if (url.host == '') {
                    continue;
                }

                if (url.protocol == 'https:' && !this.isExecutableFile(url.href)) {
                    if (url.host == 'www.youtube.com' || url.host == 'youtu.be') {
                        var video;

                        if (url.pathname == '/watch') {
                            let videoID = url.search.slice(url.search.indexOf('v=') + 2).split('&')[0];
                            video = `https://www.youtube.com/embed/${videoID}`;

                            words[i] = `<div class="link-preview">
                                            <iframe src="${video}" allowfullscreen></iframe>
                                            <a class="link" href="${url.href}" target="_blank">${url.href}</a>
                                        </div>`;
                        }
                        else {
                            video = `https://www.youtube.com/embed${url.pathname}`;
                        }

                        words[i] = `<div class="link-preview">
                                        <iframe src="${video}" allowfullscreen></iframe>
                                        <a class="link" href="${url.href}" target="_blank">${url.href}</a>
                                    </div>`;
                    }
                    else {
                        words[i] = `<a class="link" href="${url.href}" target="_blank">${url.href}</a>`;
                    }
                }
                else {
                    words[i] = `
                    <div fxLayout="column" fxLayoutAlign="center start">
                        <a class="link" href="${url.href}" target="_blank">${url.href}</a><br>
                        <small class="other-dark-text">Este link pode não ser confiável</small>
                    </div>`;
                }

            } catch (_) { }

            if (this.isEmoji(content)) {
                words = [`<span class="emoji">${content}</span>`];
            }
        }

        return this.sanitizer.bypassSecurityTrustHtml(words.join(' '));
    }

    isEmoji(content) {
        return content.replace(/\p{Emoji_Presentation}/ug, '').trim().length <= 0;
    }

    isExecutableFile(url: string) {
        return url.includes('.js') || url.includes('.html') || url.includes('.css') || url.includes('.scss');
    }

    preventHtml(content: string) {
        var div = document.createElement("div");
        div.innerHTML = content;
        let cleanText = div.innerText;
        div = null;

        return cleanText;
    }
}