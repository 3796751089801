import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-verify-code',
  templateUrl: './verify-code.component.html',
  styleUrls: ['./verify-code.component.scss']
})
export class VerifyCodeComponent implements OnInit {
  form: UntypedFormGroup;
  loading = false;
  type: VerificationType;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private notificationService: NotificationService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.type = this.route.snapshot.queryParams['type'];

    this.form = this.formBuilder.group({
      code: ['', [Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.invalid || this.type == null) {
      return;
    }

    this.loading = true;
    this.userService.validateCode(this.form.value.code, this.type).subscribe({
      next: () => {
        if (this.type == VerificationType.ResetPassword) {
          this.router.navigate(['/reset-password'], { queryParams: { code: this.form.value.code } });
        }
        else {
          this.userService.authenticationValue.isAccountActivated = true;
          this.router.navigateByUrl('/');
          this.notificationService.sucess('Conta ativada');
        }

        this.loading = false;
      },
      error: error => {
        this.notificationService.error(error);
        this.loading = false;
      }
    });
  }

  resendCode() {
    if (this.type == null) {
      return;
    }

    this.router.navigate(['/send-code'], { queryParams: { type: this.type } });
  }
}

export enum VerificationType {
  ResetPassword = 0,
  ActivateAccount = 1
}