<div class="content">
    <button mat-icon-button class="dismiss-button" (click)="dismiss()">
        <mat-icon>close</mat-icon>
    </button>
    <h1 mat-dialog-title class="title">
        <span class="icon">
            <mat-icon>notifications_active</mat-icon>
        </span>
        <label>{{ title }}</label>
    </h1>
    <p class="mat-body-1 message" [innerHTML]="message"></p>
</div>
<div class="actions">
    <button mat-button (click)="replyMessage()">
        <div class="button-content">
            <mat-icon>reply</mat-icon>
            <label>Responder</label>
        </div>
    </button>
    <button mat-button (click)="readMessages()">
        <div class="button-content">
            <mat-icon>done_all</mat-icon>
            <label>Marcar como lido</label>
        </div>
    </button>
</div>