<div [class]="'fab-container ' + position">
    <button mat-fab class="fab-toggler" color="primary" (click)="onToggleFab()"
        [@fabContainer]="{value: fabTogglerState}" [disabled]="buttonList?.length <= 0">
        <mat-icon [@fabToggler]="{value: fabTogglerState}">add</mat-icon>
    </button>
    <div [@speedDialStagger]="buttons.length">
        <button *ngFor="let button of buttons" mat-mini-fab class="fab-secondary" color="primary"
            (click)="buttonClick(button.onClick)">
            <mat-icon class="icon-small">{{ button.icon }}</mat-icon>
        </button>
    </div>
</div>