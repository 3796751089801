<body>
    <div class="form">
        <h1 class="mat-headline">Minha Coop</h1>
        <h4 class="mat-subheading-1">Autentique-se para continuar</h4>
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field>
                    <mat-label>Email ou CPF</mat-label>
                    <input type="text" matInput formControlName="login">
                </mat-form-field>
                <br>
                <mat-form-field>
                    <mat-label>Senha</mat-label>
                    <input type="password" matInput formControlName="password">
                </mat-form-field>
                <ngx-recaptcha2 *ngIf="showCaptcha" #captchaElem [siteKey]="siteKey" [useGlobalDomain]="false"
                    size="normal" hl="pt" theme="light" type="image" formControlName="reCaptcha">
                </ngx-recaptcha2>
            </p>
            <p>
                <a mat-stroked-button color="primary" (click)="forgotPassword()">Esqueceu a senha?</a>
            </p>

            <div class="button">
                <button type="submit" mat-raised-button color="primary" [disabled]="loading">Entrar</button>
            </div>

        </form>
    </div>

    <img src="assets/images/logo.png" class="logo-image">
</body>