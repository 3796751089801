import { HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Poll } from 'src/app/models/poll';
import { PollOption } from 'src/app/models/poll-option';
import { PollVote } from 'src/app/models/poll-vote';
import { NotificationService } from 'src/app/services/notification.service';
import { PostService } from 'src/app/services/post.service';

@Component({
    selector: 'app-view-poll-votes',
    templateUrl: './view-poll-votes.component.html',
    styleUrls: ['./view-poll-votes.component.scss']
})

export class ViewPollVotesComponent implements OnInit {
    poll: Poll;
    votes: PollVote[];
    displayedColumns: string[] = ['user', 'pollOption', 'entryDate'];
    options: PollOption[] = [];
    selectedOption: PollOption;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ViewPollVotesComponent>, private postService: PostService, private notificationService: NotificationService) { }

    ngOnInit() {
        this.poll = this.data?.poll;
        this.options = [{ description: 'Todos' } as PollOption, ...this.poll.options];
        this.selectedOption = this.options[0];
        this.getVotes();
    }

    getVotes() {
        let params = new HttpParams().appendAll({ pollID: this.poll?.id });

        if (this.selectedOption?.id != null) {
            params = params.appendAll({ pollOptionID: this.selectedOption?.id });
        }

        this.postService.getPollVotes(params).subscribe({
            next: votes => {
                this.votes = votes;
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    close() {
        this.dialogRef.close();
    }
}
