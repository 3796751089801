import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Authentication } from 'src/app/models/authentication';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { Extensions } from 'src/app/helpers/extensions';
import { User } from 'src/app/models/user';
import { CropImageComponent } from 'src/app/helpers/crop-image/crop-image.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { Associate } from 'src/app/models/associate';
import { concatMap, forkJoin } from 'rxjs';
import { EntryService } from 'src/app/services/entry.service';
import { CpfCnpjPipe } from 'src/app/helpers/cnpj.pipe';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ComboBox, EducationLevels, MaritalRegime, MaritalStatus } from 'src/app/view-models/combo-box';
import { ConfigureTwoFactorAuthenticatorComponent } from '../configure-two-factor-authenticator/configure-two-factor-authenticator.component';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  loggedUser: Authentication;
  user: User;
  associate: Associate | null;
  form: UntypedFormGroup;
  maritalRegimes: ComboBox[] = MaritalRegime.List;
  maritalStatus: ComboBox[] = MaritalStatus.List;
  educationLevels: ComboBox[] = EducationLevels.List
  currentSettings = {};

  @ViewChild(MatStepper, { static: true }) stepper: MatStepper;

  constructor(private notificationService: NotificationService, private userService: UserService, private dialog: MatDialog,
    private entryService: EntryService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.loggedUser = this.userService.authenticationValue;
    this.getUser();
  }

  ngAfterViewInit() {
    let setup2FA = this.route.snapshot.queryParams['setup2FA'];
    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    if (setup2FA) {
      this.stepper.selectedIndex = 1;
    }
  }

  showChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangePasswordComponent, { panelClass: "dialog-large", data: { user: this.user } });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.notificationService.sucess("Senha alterada.");
      }
    });
  }

  getUser() {
    if (!this.loggedUser.isAssociate) {
      this.userService.getUserByID({ id: this.loggedUser.userID }).subscribe({
        next: user => {
          this.user = user;
          this.user.image = this.user?.image?.length > 0 ? Extensions.convertImage(this.user.image) : null;
        },
        error: error => {
          this.notificationService.error(error);
        }
      });
    }
    else {
      forkJoin([this.userService.getUserByID({ id: this.loggedUser.userID }), this.entryService.getAssociate()]).subscribe({
        next: data => {
          this.user = data[0];
          this.user.image = this.user?.image?.length > 0 ? Extensions.convertImage(this.user.image) : null;
          this.associate = data[1];
          this.associate.cpf = CpfCnpjPipe.format(this.associate.cpf);

          this.form = new UntypedFormGroup({
            phoneNumber: new UntypedFormControl(this.associate.phoneNumber),
            maritalRegime: new UntypedFormControl(this.associate.maritalRegime),
            maritalStatus: new UntypedFormControl(this.associate.maritalStatus),
            educationLevel: new UntypedFormControl(this.associate.educationLevel),
            address: new UntypedFormControl(this.associate.address)
          });

          this.checkMaritalRegime();
        },
        error: error => {
          this.notificationService.error(error);
        }
      });
    }
  }

  changeProfilePicture(e) {
    let file: File = e.target.files[0];

    if (file && !['image/jpeg', 'image/png'].includes(file.type)) {
      this.notificationService.error('Tipo de arquivo não compatível.');
      return;
    }

    const dialogRef = this.dialog.open(CropImageComponent, { panelClass: "dialog-large", disableClose: true, data: { image: e } });

    dialogRef.afterClosed().subscribe(picture => {
      if (picture) {
        this.userService.uploadImage(picture).subscribe({
          next: () => {
            this.notificationService.sucess('Imagem atualizada.');
            this.getUser();
          },
          error: error => {
            this.notificationService.error(error);
          }
        });
      }
    });
  }

  toggle2FA() {
    if (!this.user.is2FAEnabled) {
      this.userService.enable2FA().subscribe({
        next: twoFactorAuthenticator => {
          this.user.is2FAEnabled = true;
          this.dialog.open(ConfigureTwoFactorAuthenticatorComponent, { panelClass: "dialog-large", data: { twoFactorAuthenticator } });
        },
        error: error => {
          this.notificationService.error(error);
        }
      });
    }
    else {
      this.userService.disable2FA().subscribe({
        next: () => {
          this.user.is2FAEnabled = false;
          this.notificationService.sucess('2FA desabilitado');
        },
        error: error => {
          this.notificationService.error(error);
        }
      });
    }
  }

  save() {
    let associate = {
      ID: this.associate.id,
      maritalStatus: this.form.value.maritalStatus,
      maritalRegime: this.form.value.maritalRegime,
      educationLevel: this.form.value.educationLevel
    };

    this.entryService.updateAssociateProfile(associate).pipe(concatMap(() => {
      return this.entryService.getAssociate();
    }))
      .subscribe({
        next: associate => {
          this.notificationService.sucess('Informações atualizadas.');
          this.associate = associate;
        },
        error: error => {
          this.notificationService.error(error);
        }
      });
  }

  logoutFromAllSessions() {
    this.userService.logoutFromAllSessions(this.loggedUser.userID).subscribe({
      next: () => {
        this.notificationService.sucess('Sessões encerradas.');
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  checkMaritalRegime() {
    if (this.form.value.maritalStatus == 'S') {
      this.form.patchValue({
        maritalRegime: null
      });

      this.form.controls.maritalRegime.disable();
    }
    else {
      this.form.controls.maritalRegime.enable();
    }
  }

  get noChanges() {
    return (this.associate.maritalStatus == this.form.value.maritalStatus &&
      this.associate.maritalRegime == this.form.value.maritalRegime &&
      this.associate.educationLevel == this.form.value.educationLevel);
  }
}
