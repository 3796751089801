import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AcceptanceTerm } from 'src/app/models/acceptance-term';
import { ComplianceService } from 'src/app/services/compliance.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-select-acceptance-term',
    templateUrl: './select-acceptance-term.component.html',
    styleUrls: ['./select-acceptance-term.component.scss']
})

export class SelectAcceptanceTermComponent implements OnInit {
    acceptanceTerms: AcceptanceTerm[];
    filteredAcceptanceTerms: AcceptanceTerm[] = [];
    selectedAcceptanceTerm?: AcceptanceTerm;

    @ViewChild('acceptanceTermInput') acceptanceTermInput: ElementRef<HTMLInputElement>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<SelectAcceptanceTermComponent>, private notificationService: NotificationService,
        private complianceService: ComplianceService) { }

    ngOnInit() {
        this.complianceService.getAcceptanceTerms().subscribe({
            next: acceptanceTerms => {
                this.acceptanceTerms = acceptanceTerms;
            },
            error: error => {
                this.notificationService.error(error);
            }
        });
    }

    filterBySearch(value: string) {
        if (value.length <= 0) {
            this.filteredAcceptanceTerms = [];
            return;
        }

        this.filteredAcceptanceTerms = this.acceptanceTerms?.filter(u => u.title.toLocaleLowerCase().includes(value.toLocaleLowerCase())).slice(0, 20);
    }

    applySearchSelection(acceptanceTerm?: AcceptanceTerm) {
        this.selectedAcceptanceTerm = acceptanceTerm;
        this.acceptanceTermInput.nativeElement.value = acceptanceTerm?.title ?? '';

        if (acceptanceTerm == null) {
            this.filteredAcceptanceTerms = [];
        }
    }

    save() {
        if (this.selectedAcceptanceTerm == null) {
            this.notificationService.warning('Existem campos não preenchidos.');
            return;
        }

        this.dialogRef.close(this.selectedAcceptanceTerm);
    }

    close() {
        this.dialogRef.close();
    }
}
