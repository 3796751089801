import * as CryptoJS from 'crypto-js';

export module Encryptions {

    export function encryptAES(content: string, senderID: number) {
        try {
            let keyAndIV = this.initKeyAndIV(senderID);
            let key = CryptoJS.enc.Utf8.parse(keyAndIV[0]);
            let iv = CryptoJS.enc.Utf8.parse(keyAndIV[1]);

            let encrypted = CryptoJS.AES.encrypt(content, key, { iv: iv, mode: CryptoJS.mode.CBC });
            return encrypted.toString();
        }
        catch {
            return;
        }
    }

    export function decryptAES(content: string, senderID: number) {
        try {
            let keyAndIV = this.initKeyAndIV(senderID);
            let key = CryptoJS.enc.Utf8.parse(keyAndIV[0]);
            let iv = CryptoJS.enc.Utf8.parse(keyAndIV[1]);

            let decrypted = CryptoJS.AES.decrypt(content, key, { iv: iv, mode: CryptoJS.mode.CBC });

            if (decrypted == null || decrypted.toString().length <= 0) {
                throw null;
            }

            return { content: CryptoJS.enc.Utf8.stringify(decrypted), isCorrupt: false };
        }
        catch (e) {
            return { content: 'Esta mensagem foi corrompida', isCorrupt: true };
        }
    }

    export function decryptFileAES(bytes: Uint8Array, senderID: number) {
        try {
            let keyAndIV = this.initKeyAndIV(senderID);
            let key = CryptoJS.enc.Utf8.parse(keyAndIV[0]);
            let iv = CryptoJS.enc.Utf8.parse(keyAndIV[1]);

            let encryptedData = CryptoJS.lib.WordArray.create(bytes);
            let decryptedData = CryptoJS.AES.decrypt({ ciphertext: encryptedData }, key, { iv: iv, mode: CryptoJS.mode.CBC });
            let decryptedArray = new Uint8Array(decryptedData.sigBytes);
            
            for (let i = 0; i < decryptedData.sigBytes; i++) {
                decryptedArray[i] = decryptedData.words[i >>> 2] >>> (24 - (i % 4) * 8) & 0xff;
            }

            return decryptedArray;
        }
        catch (e) {
            return new Uint8Array(0);
        }
    }

    export function initKeyAndIV(userID: number) {
        let s = '';
        let v = '';
        for (let i = userID; i < (userID + 32); i++) {
            s += (i * (userID * 7)).toString();
            v += (i * userID).toString();
        }

        return [s.substring(0, 32), v.substring(0, 16)];
    }
}