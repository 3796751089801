<mat-card>
    <div (ngxVisibility)="updateVisibility($event)" [ngxVisibilityThreshold]="1">
        <div fxLayout="row" fxLayoutAlign="space-between start" style="margin-left: 10px; margin-top: 10px;">
            <div fxLayout="column" fxLayoutAlign="center start">
                <mat-card-title>{{ post.title }}</mat-card-title>
                <span class="post-info">
                    <small class="grey">Publicado em {{ post.postDate | date:"dd/MM/yyyy HH:mm" }}
                        {{ post.createdBy != null ? ', por ' + post.createdBy : '' }}
                    </small>
                    <div *ngIf="post.edited" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <small class="grey">•</small>
                        <div class="grey" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="2px">
                            <mat-icon class="icon-sm">edit</mat-icon>
                            <small>Editado</small>
                        </div>
                    </div>
                </span>
                <mat-card-subtitle [innerHTML]="getPostDescription(post)"></mat-card-subtitle>
            </div>
            <button mat-icon-button [matMenuTriggerFor]="menu"
                *ngIf="allowPostManagement && (post.allowEdition || allowEditingAnyPost)">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="editPost()">
                    <mat-icon>create</mat-icon>
                    <span>Editar</span>
                </button>
                <button mat-menu-item (click)="deletePost()">
                    <mat-icon>delete</mat-icon>
                    <span>Excluir</span>
                </button>
            </mat-menu>
        </div>
        <div class="post-content" *ngIf="post.attachments?.length > 0">
            <app-frame-file class="frame" [filePath]="post.attachments[post.selectedAttachmentIndex ?? 0].filePath"
                [fileType]="post.attachments[post.selectedAttachmentIndex ?? 0].fileType"></app-frame-file>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="attachments-carrousel"
                *ngIf="post.attachments?.length > 1">
                <a *ngFor="let attachment of post.attachments" fxLayout="column" fxLayoutAlign="center center"
                    fxLayoutGap="4px" class="carrousel-item" (click)="selectCarrouselAttachment(attachment)"
                    [ngClass]="{'carrousel-current-selection': (post.selectedAttachmentIndex ?? 0) == post.attachments.indexOf(attachment) }">
                    <img [src]="attachment.filePath | safe: 'resourceUrl'" class="small-image"
                        *ngIf="isFileImage(attachment.fileType)">
                    <iframe [src]="attachment.filePath | safe: 'resourceUrl'" class="small-iframe"
                        *ngIf="!isFileImage(attachment.fileType) && !isFileVideo(attachment.fileType)"></iframe>
                    <img src="assets/images/video.png" class="small-image"
                        *ngIf="isFileVideo(attachment.fileType)">
                </a>
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px" class="post-actions">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <button mat-icon-button [class]="post.isLiked ? 'like-button liked' : 'like-button not-liked'"
                (click)="ratePost()">
                <mat-icon class="like-icon">favorite</mat-icon>
            </button>
            <small class="grey">{{ post.likedByCount }}</small>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="grey">
            <mat-icon>visibility</mat-icon>
            <small>{{ post.readByCount }} {{ post.readByCount == 1 ? 'visualização' : 'visualizações' }}</small>
        </div>
    </div>
</mat-card>