import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Parameter } from "../models/parameter";
import { Role } from "../models/role";

@Injectable({
    providedIn: 'root'
})

export class AdminService {
    constructor(private http: HttpClient) { }

    getParameters() {
        return this.http.get<Parameter[]>(`${environment.API}parameters`).pipe();
    }

    getParameterByCode(params) {
        return this.http.get<Parameter>(`${environment.API}parameters/code`, { params }).pipe();
    }

    putParameter(parameterID: number, body) {
        return this.http.put<any>(`${environment.API}parameters/${parameterID}`, body).pipe();
    }

    validateParameter(body) {
        return this.http.post<any>(`${environment.API}parameters/validate-parameter`, body).pipe();
    }

    getRoles() {
        return this.http.get<Role[]>(`${environment.API}roles`).pipe();
    }

    createRole(body) {
        return this.http.post<any>(`${environment.API}roles`, body).pipe();
    }

    updateRole(roleID: number, body) {
        return this.http.put<any>(`${environment.API}roles/${roleID}`, body).pipe();
    }

    deleteRole(body) {
        return this.http.delete<any>(`${environment.API}roles`, { body: body }).pipe();
    }
}