<mat-card>
    <div fxLayout="row" fxLayoutAlign="space-between start" style="margin-left: 10px; margin-top: 10px;">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                <mat-card-title>{{ poll.title }}</mat-card-title>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <span class="bg-label warning mat-small" style="line-height: 1;" *ngIf="poll.isExpired">
                        VOTAÇÃO FECHADA
                    </span>
                    <span class="bg-label success mat-small" style="line-height: 1;" *ngIf="poll.hasVoted">
                        VOCÊ VOTOU
                    </span>
                </div>
            </div>
            <span class="poll-info">
                <small class="grey">Votação aberta até {{ poll.dueDate | date:"dd/MM/yyyy HH:mm" }}
                    {{ poll.createdBy != null ? ', criada por ' + poll.createdBy : '' }}
                </small>
            </span>
        </div>
        <button mat-icon-button [matMenuTriggerFor]="pollMenu"
            *ngIf="allowPostManagement && (poll.allowDeletion || allowEditingAnyPost)">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #pollMenu="matMenu">
            <button mat-menu-item (click)="viewVotes()">
                <mat-icon>how_to_vote</mat-icon>
                <span>Ver Votos</span>
            </button>
            <button mat-menu-item (click)="deletePoll()">
                <mat-icon>delete</mat-icon>
                <span>Excluir</span>
            </button>
        </mat-menu>
    </div>
    <div class="poll-content">
        <mat-radio-group fxLayout="column" fxLayoutAlign="start start" [ngClass]="{'readonly': !poll.allowVoting}">
            <mat-card *ngFor="let option of poll.options" [ngClass]="{'poll-option-none': !poll.hasVoted}"
                [class]="option.isSelected ? 'poll-option poll-option-selected' : 'poll-option poll-option-unselected'">
                <mat-radio-button [value]="option.id" color="primary" [checked]="option.isSelected"
                    (click)="voteInPoll(option)">
                    <div class="poll-option-content">
                        <small class="poll-option-text">{{ option.description }}</small>
                        <small class="poll-option-text" *ngIf="poll.hasVoted || poll.isExpired">
                            {{ option.percentage | percent }}
                        </small>
                    </div>
                </mat-radio-button>
                <mat-progress-bar class="votes-percentage-bar" [value]="option.percentage * 100"
                    *ngIf="poll.hasVoted || poll.isExpired"></mat-progress-bar>
                <small class="poll-votes" *ngIf="poll.hasVoted || poll.isExpired">{{ option.votes }} {{ option.votes == 1 ? 'voto' :
                    'votos' }}</small>
            </mat-card>
        </mat-radio-group>
    </div>
</mat-card>