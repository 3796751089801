<div class="mat-dialog-content">
  <p>
    <mat-icon class="{{ 'icon-' + icon}}" color="{{iconColor}}">{{icon}}</mat-icon>
  </p>
  <h1 mat-dialog-title>
    {{title}}
  </h1>
  <p class="mat-body-1" [innerHTML]="message"></p>
</div>

<div mat-dialog-actions align="center">
  <button mat-raised-button color="{{iconColor}}" (click)="onDismiss()" tabindex="-1">OK</button>
</div>